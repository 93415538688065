import React, { useState } from "react";
import feature from "../../assets/fake-data/landingpage-two/feature";
import feature3 from '../../assets/images/features/3.png'
import feature5 from '../../assets/images/features/5.png'
import penruller from '../../assets/images/icon/pen&ruller.svg'
import code from '../../assets/images/icon/code.svg'
import headphones from '../../assets/images/icon/headphones.svg'
import groupchat from '../../assets/images/icon/groupchat.svg'



const Features = () => {


  return (
    <>
      <section className="bg-[url('../images/home_sec_features.jpg')] bg-center py-20 bg-white relative">
        <div className="container">
          {/* <div className="grid grid-cols-1">
            <div className="text-center">
              <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {dataBlock.title}
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
                {dataBlock.subTitle}
              </p>
            </div>
          </div> */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-[30px] mt-16 items-center">
            {/* <div>
              <img
                src={feature3}
                alt="business"
                className="mx-auto"
              />
            </div> */}
            <div>
              <h3 className="md:text-[32px] text-2xl leading-[1.4] font-bold">
              Orientações para efetuar o processo do recurso
              </h3>
              <div className="space-y-8 mb-4">
                <div className="flex items-start gap-5 mt-8">
                  <div className="grow">
                    <i className="mdi mdi-check bg-custom text-white text-lg leading-[26px] h-[26px] w-[26px] relative top-1 text-center rounded-full inline-block"></i>
                  </div>
                  <div>
                    <h3 className="text-lg font-bold mb-4">
                    Recurso criado
                    </h3>
                    <p className="text-[15px] leading-[22px] text-muted">
                    Após receber o PDF do recurso, imprimir e assinar na última página. &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp;
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-5 mt-8">
                  <div className="grow">
                    <i className="mdi mdi-check bg-custom text-white text-lg leading-[26px] h-[26px] w-[26px] relative top-1 text-center rounded-full inline-block"></i>
                  </div>
                  <div>
                    <h3 className="text-lg font-bold mb-4">
                    Documentos necessários
                    </h3>
                    <p className="text-[15px] leading-[22px] text-muted">
                    Anexar ao recurso os documentos necessários exigidos pelo DETRAN. &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp;
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-5 mt-8">
                  <div className="grow">
                    <i className="mdi mdi-check bg-custom text-white text-lg leading-[26px] h-[26px] w-[26px] relative top-1 text-center rounded-full inline-block"></i>
                  </div>
                  <div>
                    <h3 className="text-lg font-bold mb-4">
                    DETRAN
                    </h3>
                    <p className="text-[15px] leading-[22px] text-muted">
                    Protocole no DETRAN da sua cidade e aguarde o prazo para a conclusão do julgamento do seu recurso.
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-5 mt-8">
                  <div className="grow">
                    <i className="mdi mdi-check bg-custom text-white text-lg leading-[26px] h-[26px] w-[26px] relative top-1 text-center rounded-full inline-block"></i>
                  </div>
                  <div>
                    <h3 className="text-lg font-bold mb-4">
                    Acompanhamento
                    </h3>
                    <p className="text-[15px] leading-[22px] text-muted">
                    Mantenha o endereço de cadastro de sua CNH atualizado no DETRAN, para que você receba a notificação do julgamento do recurso.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
          {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-[30px] mt-16 items-center">
            <div>
              <h3 className="text-[32px] font-bold leading-[1.4] max-w-[520px]">
                Here's how we do things differently
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] mt-6">
                <div className="">
                  <div className="bg-gradient-to-r from-white to-custom/20 w-[54px] h-[54px] flex items-center justify-center -rotate-45 leading-[54px] rounded-md ">
                    <img
                      src={penruller}
                      alt="feture"
                      className="mx-auto inline-block h-[30px] rotate-45"
                    />
                  </div>
                  <div className="mt-6">
                    <h5 className="font-bold text-xl">Well Documented</h5>
                    <p className="text-muted mt-4">
                      Easily publish your coupans and one of your coupens.
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="bg-gradient-to-r from-white to-custom/20 w-[54px] h-[54px] flex items-center justify-center -rotate-45 leading-[54px] rounded-md ">
                    <img
                      src={code}
                      alt="feture"
                      className="mx-auto inline-block h-[30px] rotate-45"
                    />
                  </div>
                  <div className="mt-6">
                    <h5 className="font-bold text-xl">Highly Customizable</h5>
                    <p className="text-muted mt-4">
                      Easily publish your coupans and one of your coupens.
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="bg-gradient-to-r from-white to-custom/20 w-[54px] h-[54px] flex items-center justify-center -rotate-45 leading-[54px] rounded-md ">
                    <img
                      src={headphones}
                      alt="feture"
                      className="mx-auto inline-block h-[30px] rotate-45"
                    />
                  </div>
                  <div className="mt-6">
                    <h5 className="font-bold text-xl">24/7 Support</h5>
                    <p className="text-muted mt-4">
                      Easily publish your coupans and one of your coupens.
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="bg-gradient-to-r from-white to-custom/20 w-[54px] h-[54px] flex items-center justify-center -rotate-45 leading-[54px] rounded-md ">
                    <img
                      src={groupchat}
                      alt="feture"
                      className="mx-auto inline-block h-[30px] rotate-45"
                    />
                  </div>
                  <div className="mt-6">
                    <h5 className="font-bold text-xl">Community</h5>
                    <p className="text-muted mt-4">
                      Easily publish your coupans and one of your coupens.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img
                src={feature5}
                alt="business"
                className="mx-auto"
              />
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Features;
