import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, A11y, Pagination } from "swiper";
import swiperdata from "../../assets/fake-data/swiperdata";
import clientSide from "../../assets/images/client_side.png";

const SwiperLandingPageOne = () => {
  const [dataBlock] = useState({
    clientSideImage: clientSide,
    subtitle:
      " Due to its widespread use as filler text for layouts, non-readability is of great importance.",
    title: "What Our Users Have To Say.",
  });

  return (
    <>
      <section className="bg-light pt-20">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] items-center">
            <div className="md:order-1 order-2">
              <img
                src={dataBlock.clientSideImage}
                alt="client side"
                className="mx-auto"
              />
            </div>
            <div className="relative client-sec md:order-2 order-1">
              <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {dataBlock.title}
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
                {dataBlock.subtitle}
              </p>
              <div className="mt-12 mb-10">
                <p className="text-muted">
                  More Over <span className="text-custom">3200+ Customers</span>
                </p>
              </div>
              <Swiper
                modules={[Scrollbar, A11y, Pagination]}
                slidesPerView="auto"
                pagination={{ el: ".swiper-pagination" }}
                loop={true}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  767: {
                    slidesPerView: 1,
                  },
                  991: {
                    slidesPerView: 1,
                  },
                }}
              >
                <div className="swiper-wrapper">
                  {swiperdata.map((item) => (
                    <SwiperSlide key={item.id}>
                      <div className="flex items-start gap-8">
                        <div className="testi-icon testi-left h-[52px] text-center w-[52px] leading-[52px] rounded-full text-white bg-custom inline-block text-[42px] shrink-0">
                          <i className={item.icon}></i>
                        </div>
                        <div className="testi-desc">
                          <h3 className="capitalize text-2xl leading-[1.6] font-semibold">
                            {item.description}
                          </h3>
                          <h5 className="mt-6 font-bold text-xl">{item.user}</h5>
                          <p className="text-muted mt-1">{item.address}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </div>

                <div className="swiper-pagination space-x-2 mt-5"></div>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SwiperLandingPageOne;
