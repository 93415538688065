import { cpf, cnpj } from 'cpf-cnpj-validator';

export const pages = {
  home: '/',
  Fase: '/phase-process',
  'Processo Administrativo': '/phase-administrative-process',
  'Processo Administrativo Por Suspenção': '/phase-process-suspension',
  'Infração Específica': '/phase-infringement-process-suspension',
};

export const capitalizeFirstLetter = (string) => {
  return string[0].toUpperCase() + string.slice(1);
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('data_user') != null
        ? JSON.parse(localStorage.getItem('data_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('data_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('data_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const delCurrentUser = () => {
  try {
    localStorage.removeItem('access_token');
    localStorage.removeItem('data_user');
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : delCurrentUser -> error', error);
  }
};

export const validateEmail = (email) => {
  let tested = null;
  try {
    const re = /\S+@\S+\.\S+/;
    tested = re.test(email);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : validateEmail -> error', error);
  }
  return tested;
};

export const validateCpfCnpj = (value) => {
  try {
    if (cpf.isValid(value.replace(/\.|-/g, ''))) return true;
    if (cnpj.isValid(value.replace(/\.|-/g, ''))) return true;
    return false;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : validateCpfCnpj -> error', error);
  }
  return false;
};

export const validatePhone = (value) => {
  try {
    const valueReplaced = value
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '')
      .replace(' ', '');
    const regex = new RegExp(
      '^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$'
    );
    if (regex.test(valueReplaced)) return true;
    return false;
  } catch (error) {
    return false;
  }
};

export const validatePassword = (value, confirm) => {
  try {
    if (value !== confirm)
      return { error: true, message: 'As senhas não são iguais!' };
    if (value.length < 6)
      return { error: true, message: 'A senha deve ter pelo menos 6 dígitos!' };
    return { error: false, message: '' };
  } catch (error) {
    return { error: true, message: 'Erro ao validar senha!' };
  }
};

export const validateFormRegister = (values, erros) => {
  try {
    if (
      values.name &&
      values.email &&
      values.cpfCnpj &&
      values.phone &&
      values.password &&
      values.confirmPassword &&
      !erros.errorEmail &&
      !erros.errorCpfCnpj &&
      !erros.errorPhone &&
      !erros.errorPassword
    )
      return true;
    return false;
  } catch (error) {
    return false;
  }
};

export const validateFormLogin = (values, erros) => {
  try {
    if (values.email && values.password && !erros.errorPassword) return true;
    return false;
  } catch (error) {
    return false;
  }
};

export const validatePasswordLogin = (value) => {
  try {
    if (value.length < 6)
      return { error: true, message: 'A senha deve ter pelo menos 6 dígitos!' };
    return { error: false, message: '' };
  } catch (error) {
    return { error: true, message: 'Erro ao validar senha!' };
  }
};

export const isNumeric = (value) => {
  return /^-?\d+$/.test(value.replace('.', '').replace('-', ''));
};

export const enableButton = (values) => {
  try {
    if (
      !values.cpfCnpj ||
      !values.name ||
      !values.cep ||
      !values.number ||
      !values.neighborhood ||
      !values.street ||
      !values.city ||
      !values.cnh ||
      !values.pcNet ||
      !values.phone
    )
      return false;
    return true;
  } catch (error) {
    return false;
  }
};

export const validateFormPersonal = (values, errors, setErros) => {
  try {
    if (!values.name) {
      setErros({ ...errors, errorName: true });
      return true;
    }
    if (!values.cep) {
      setErros({ ...errors, errorCep: true });
      return true;
    }
    if (!values.number || !isNumeric(values.number)) {
      setErros({ ...errors, errorNumber: true });
      return true;
    }
    if (!values.neighborhood) {
      setErros({ ...errors, errorNeighborhood: true });
      return true;
    }
    if (!values.street) {
      setErros({ ...errors, errorStreet: true });
      return true;
    }
    if (!values.city) {
      setErros({ ...errors, errorCity: true });
      return true;
    }
    if (!values.cnh) {
      setErros({ ...errors, errorCnh: true });
      return true;
    }
    if (!values.cnh) {
      setErros({ ...errors, errorPcNet: true });
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export function saveSession() {
  let session = JSON.parse(localStorage.getItem('session')) || {};

  const currentTime = new Date();
  const lastUpdated = new Date(session.lastUpdated || 0);

  if (!session.id || (currentTime - lastUpdated) > (24 * 60 * 60 * 1000)) {
    const newId = Math.random().toString(36).substring(2);

    session = {
      id: newId,
      lastUpdated: currentTime.toISOString()
    };

    localStorage.setItem('session', JSON.stringify(session));
  }

  return session;
}
