import React from "react";
import FooterTwo from "../components/footers/FooterTwo";
import FAQ from "../features/common/FAQ";
import SmartFeature from "../features/common/SmartFeature";
import MainHeroSection from "../features/common/MainHeroSection";

const FAQPage = () => {
  return (
    <>
      <MainHeroSection title="FAQ" subTitle="Se não encontrar uma resposta à sua pergunta, você pode nos enviar um e-mail a partir do nosso formulário de contato ou ChatBot no canto direito inferior da página." />
      <SmartFeature/> 
      <FAQ/>
      <FooterTwo/>
    </>
  );
};

export default FAQPage;
