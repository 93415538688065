import React, { useState, useEffect, useCallback } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import styled from 'styled-components';
import Box from '@mui/material/Box';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { Scrollbar, A11y, Pagination } from "swiper";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress, Button } from '@material-ui/core';
import { Alert } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import Api from '../services/api';
import {
  validateCpfCnpj,
  enableButton,
  validateFormPersonal,
  isNumeric,
} from '../helpers/Utils';
import ColoredLinearProgress from '../common/LineProgress';
import FooterTwo from "../components/footers/FooterTwo";
import CardDataInfo from '../features/common/CardDataInfo';

import swiperdata from "../assets/fake-data/swiperdata";

import Credit from '../assets/images/credit.svg';
import CreditActive from '../assets/images/credit-active.svg';
import Debit from '../assets/images/debit.svg';
import DebitActive from '../assets/images/debit-active.svg';
import Pix from '../assets/images/pix.svg';
import PixActive from '../assets/images/pix-active.svg';
import Barcode from '../assets/images/barcode.svg';
import BarcodeActive from '../assets/images/barcode-active.svg';
import clientSide from "../assets/images/client_side-orig.png";

import { validateEmail, validatePhone, saveSession } from '../helpers/Utils';

const articlesSuspensionCodes = [
  'Art. 165',
  'Art. 165-a',
  'Art. 165-b',
  'Art. 170',
  'Art. 173',
  'Art. 174',
  'Art. 175',
  'Art. 176',
  'Art. 191',
  'Art. 210',
  'Art. 218-iii',
  'Art. 244-i',
  'Art. 244-ii',
  'Art. 244-ii',
  'Art. 244-iv',
  'Art. 244-v',
  'Art. 253-a',
];

const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-beteween;
  margin-bottom: 2rem;

  @media only screen and (max-width: 880px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

const AreaPayment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 30px;
  width: 100%;
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 1px 1px 5px rgb(1 1 1 / 5%);
  border-radius: 16px;
`;

const AreaButton = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 8px 8px 8px;
  width: 100%;
  justify-content: space-between;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const ButtonContinueHidden = styled.div`
  display: flex;
  width: 100%;
  background: #a2d0a4;
  border-radius: 4px;
  justify-content: center;
  padding: 15px 0px 15px 0px;
  margin: 5px;
  cursor: pointer;
`;

const LabelButtonContinue = styled.div`
  height: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: white;
  margin: 0px 2px
`;

const Item = styled.div`
  display: flex;
  align-items: center; 
  justify-content: center;
  width: 100%;
  text-align: center;
  flex-direction: row;

  img {
    width: 30px;
  }

  @media only screen and (max-width: 669px) {
    flex-direction: column;
    text-align: left;
    justify-content: left;
  }
`;

const Description = styled.div`
  color: #c2c2c2;
  font-weight: 900;
  font-size: 12px;
  margin: 5px;

  @media only screen and (max-width: 669px) {
    text-align: left;
    justify-content: left;
    margin: 0px;
  }
`;

const ItemPrice = styled.div`
  width: 70%;
  text-align: left;
`;

const AreaItemPrice = styled.div`
  width: 100%;
  text-align: right;
  justify-content: right;
  display: flex;
  flex-direction: row;
`;

const AreaValue = styled.div`
  width: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;

const TextOriginal = styled.div`
  color: #dc3545;
  font-weight: 900;
  font-size: 40px;
  font-family: sans-serif;

  @media only screen and (max-width: 557px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 463px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 420px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 374px) {
    font-size: 10px;
  }
`;

const Text = styled.div`
  font-family: sans-serif;
  font-size: 19px;
  line-height: 20px;
  color: #242424;
  font-weight: 900;
`;

const LegendPrice = styled.div`
  color: #f09351;
  font-weight: 900;
  font-size: 12px;
`;

const ButtonBack = styled.button`
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  border: 2px solid #4caf50;
  border-radius: 4px;
  justify-content: center;
  margin: 5px;
  padding: 1rem;
  cursor: pointer;
`;

const LabelButton = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: black;
  text-align: center;
`;

const PersonalData = () => {
  const api = Api();
  const sessionId = saveSession()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryPhase = queryParams.get('phase');

  const [values, setValues] = useState({
    cpfCnpj: '',
    rg: '',
    agency: '',
    name: '',
    cep: '',
    number: '',
    neighborhood: '',
    street: '',
    complement: '',
    city: '',
    cnh: '',
    birthDate: '',
    dateOfFirstLicense: '',
    pcNet: '',
    phone: null,
  });
  const [errors, setErros] = useState({
    errorRg: null,
    errorAgency: null,
    errorName: null,
    errorCep: null,
    errorNumber: null,
    errorNeighborhood: null,
    errorStreet: null,
    errorComplement: null,
    errorCity: null,
    errorCnh: null,
    errorPcNet: null,
  });
  const [errorEmail, setErrorEmail] = useState(false);
  const [message, setMessage] = useState(null);
  const [errorCpfCnpj, setErrorCpfCnpj] = useState(null);
  const [errorRg, setErrorRg] = useState(null);
  const [errorCep, setErrorCep] = useState(null);
  const [errorPhone, setErrorPhone] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('CREDIT');
  const [loading, setLoading] = useState(false);
  const [copyLink, setCopyLink] = useState('');

  const [inciseds, setIncised] = useState([]);
  const [phase, setPhase] = useState(queryPhase ? queryPhase : null);
  const [processType, setProcess] = useState(null);
  const [suspension, setSuspension] = useState(null);
  const [suspensionInfrigement, setSuspensionInfrigement] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);

  const [checkedTerm, setCheckedTerm] = useState(true);
  const [checkedMails, setCheckedMails] = useState(true);
  const [cont, setcontinue] = useState(false);
  const [cart_resource_id, setCartResourceId] = useState(false);

  const [dataBlock] = useState({
    clientSideImage: clientSide,
    subtitle:
      " Due to its widespread use as filler text for layouts, non-readability is of great importance.",
    title: "What Our Users Have To Say.",
  });

  const onSendEvent = useCallback(async () => {
    await api.post('/view-event', {
      session_id: sessionId.id,
      type_view: 'FORM_EVENT'
    });
  }, [api, sessionId]);
  

  useEffect(() => {
    if (email && !validateEmail(email)) {
      setErrorEmail(true);
    } else {
      setErrorEmail(false);
    }

    if (values.phone && !validatePhone(values.phone)) {
      setErrorPhone(true);
    } else {
      setErrorPhone(false);
    }
  }, [values, email]);

  useEffect(() => {
    onSendEvent();
    const url = `/incised`;
    api
      .get(url)
      .then((response) => {
        if (response.data.error) {
          setMessage(
            response.data.message
              ? response.data.message
              : 'Error ao buscar infrações, regarregue a página!'
          );
        }
        if (!response.data.data.error) {
          const newData = articlesSuspensionCodes.map(code => {
            const matchingItem = response.data.data.find(item => item.infringement_article === code);
            return matchingItem ? {
              ...matchingItem,
              label: `${code} - ${matchingItem.description}`,
              key: matchingItem.id
            } : null;
          }).filter(item => item !== null);
          setIncised(newData);
          setLoading(false);
        }
      })
      .catch((error) => {
        setMessage(
          error?.response?.data?.data?.message
            ? error.response.data.data.message
            : 'Ocorreu um erro inesperado!'
        );

        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (values.cpfCnpj && !validateCpfCnpj(values.cpfCnpj)) {
      setErrorCpfCnpj(true);
    } else {
      setErrorCpfCnpj(false);
    }

    if (values.rg && !isNumeric(values.rg)) {
      setErrorRg(true);
    } else {
      setErrorRg(false);
    }

    if (values.cep && !isNumeric(values.cep)) {
      setErrorCep(true);
    } else {
      setErrorCep(false);
    }
  }, [values.cep, values.cpfCnpj, values.rg]);

  const formatCpfCnpj = (value) => {
    const cleaned = value.replace(/\D/g, '');
  
    const isCpf = cleaned.length === 11;
  
    const formatted = isCpf ?
      cleaned.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') :
      cleaned.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  
    return formatted;
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    
    const formatted = cleaned.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  
    return formatted;
  };

  const handleChange = (prop) => (event) => {
    if (prop === 'phone') {
      const regex = /^\(\d{2}\) \d{9}$/;
      const { value } = event.target;
      setErrorPhone(!regex.test(value));
      setValues({ ...values, phone: formatPhoneNumber(value) });
    } else if (prop === 'cpfCnpj') {
      // const regex = /^\(\d{2}\) \d{9}$/;
      // const { value } = event.target;
      // setErrorPhone(!regex.test(value));
      setValues({ ...values, cpfCnpj: formatCpfCnpj(event.target.value) });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };



  const handlePaymentMethod = (value) => {
    setPaymentMethod(value);
  };

  async function onContinue(copy) {
    const errorForm = validateFormPersonal(values, errors, setErros);
    setMessage(errorForm ? 'Existem campos incorretos!' : null);
    if (!errorForm && processType && suspension && phase) {
      setLoading(true);

      await api
        .post('/resource', {
          resource_personal_data: {
            ...values,
            cpf_cnpj: values.cpfCnpj,
            birth_date: values.birthDate,
            date_of_first_license: values.dateOfFirstLicense,
            pc_net: values.pcNet,
          },
          payment_method: paymentMethod,
          administrative_process: {
            active: true,
            phase: phase,
            type_process: processType,
            type_suspension: suspension,
            cart_resource_id: cart_resource_id,
            incised_id: suspensionInfrigement
              ? suspensionInfrigement.id
              : null,
            /*eslint-disable */
            article: suspensionInfrigement
              ? suspensionInfrigement.infringement_article
              : null,
          },
          user: {
            name: name,
            email: email,
            phone: values.phone,
          }
        })
        .then((response) => {
          if (response.data.error) {
            setMessage(
              response.data.message
                ? response.data.message
                : 'Error ao criar recurso, tente novamente!'
            );
          }
          if (!response.data.data.error) {
            localStorage.setItem(
              'resource_id',
              response.data.data.data.resource_id
            );
            setTimeout(() => setLoading(false), 1000);
            if (copy) {
              setCopyLink('Copiado!');
              navigator.clipboard.writeText(
                response.data.data.data.data_link.shortUrl
              );
            } else {
              setCopyLink('');
              window.location.replace(
                response.data.data.data.data_link.shortUrl
              );
            }
          }
        })
        .catch((error) => {
          setMessage(
            error?.response?.data?.data?.message
              ? error.response.data.data.message
              : 'Ocorreu um erro inesperado!'
          );

          setTimeout(() => setLoading(false), 2000);
        });
    }
  }

  async function onSendCart() {
    const errorForm = validateFormPersonal(values, errors, setErros);
    setMessage(errorForm ? 'Existem campos incorretos ou sem preencher, verifique se os dados foram inseridos!' : null);
    if (!errorForm && processType && suspension && phase) {
      await api
        .post('/cart-resource', {
          ...values,
          cpf_cnpj: values.cpfCnpj,
          birth_date: values.birthDate,
          date_of_first_license: values.dateOfFirstLicense,
          pc_net: values.pcNet,
          phase: phase,
          type_process: processType,
          type_suspension: suspension,
          incised_id: suspensionInfrigement
            ? suspensionInfrigement.id
            : null,
          client_name: name,
          client_email: email,
          client_phone: values.phone,
        })
        .then((response) => {
          if (!response.data.data.error) {
            setCartResourceId(response.data.data.cart_resource_id)
          }
        })
    }
  }

  const handleProcess = (e) => {
    setProcess(e.target.value);
    if (e.target.value !== 'SUSPENSION') {
      setSuspension(null);
      setSuspensionInfrigement(null)
    }
  }

  const handleSuspension = (e) => {
    setSuspension(e.target.value);
    if (e.target.value !== 'INFRINGEMENT') {
      setSuspensionInfrigement(null)
    }
  }

  const handleSuspensionInfrigementChange = (event, newValue) => {
    setSuspensionInfrigement(newValue);
  };

  async function verifyToPayment() {
    if (!enableButton(values) ||
    errorCpfCnpj ||
    !checkedTerm ||
    errorEmail ||
    errorPhone ||
    !checkedMails ||
    name === '' ||
    email === '' ||
    errorCep ) {
      setMessage("Verifique se o formulário foi preenchido corretamente!");
    } else {
      setcontinue(true);
      setMessage(null);
      await onSendCart()
    }
  }

  return (
    <>
    {/* <section className="bg-light pt-20">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] items-center">
            <div className="md:order-1 order-2">
              <img
                src={dataBlock.clientSideImage}
                alt="client side"
                className="mx-auto"
                style={{width: '70%'}}
              />
            </div>
            <div className="relative client-sec md:order-2 order-1">
              <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {dataBlock.title}
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
                {dataBlock.subtitle}
              </p>
              <div className="mt-12 mb-10">
                <p className="text-muted">
                  More Over <span className="text-custom">3200+ Customers</span>
                </p>
              </div>
              <Swiper
                modules={[Scrollbar, A11y, Pagination]}
                slidesPerView="auto"
                pagination={{ el: ".swiper-pagination" }}
                loop={true}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  767: {
                    slidesPerView: 1,
                  },
                  991: {
                    slidesPerView: 1,
                  },
                }}
              >
                <div className="swiper-wrapper">
                  {swiperdata.map((item) => (
                    <SwiperSlide key={item.id}>
                      <div className="flex items-start gap-8">
                        <div className="testi-icon testi-left h-[52px] text-center w-[52px] leading-[52px] rounded-full text-white bg-custom inline-block text-[42px] shrink-0">
                          <i className={item.icon}></i>
                        </div>
                        <div className="testi-desc">
                          <h3 className="capitalize text-2xl leading-[1.6] font-semibold">
                            {item.description}
                          </h3>
                          <h5 className="mt-6 font-bold text-xl">{item.user}</h5>
                          <p className="text-muted mt-1">{item.address}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </div>

                <div className="swiper-pagination space-x-2 mt-5"></div>
              </Swiper>
            </div>
          </div>
        </div>
      </section> */}
    <section className="md:pt-[170px] pt-[120px] pb-20 md:pb-[90px] bg-cover bg-[url(../images/bg-pages.jpg)] relative">
     <div className="container">
      <div className="row mb-5">
        <h3 className="text-[25px] md:text-[40px]relative z-10">
          Dados da infração
        </h3>
      </div>
        <div>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FormControl fullWidth required>
              <FormLabel id="demo-radio-buttons-group-label">Em qual fase está o processo?</FormLabel>
              <RadioGroup
                style={{ marginLeft: 5 }}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={queryPhase}
                name="radio-buttons-group"
                onClick={() => onSendEvent()}
                onChange={ (e) => setPhase(e.target.value)}
              >
                <FormControlLabel value="PREVIEW" control={<Radio />} label="Portaria inaugural / Defesa prévia" />
                <FormControlLabel value="JARI" control={<Radio />} label="Portaria punitiva / Recurso à JARI" />
                <FormControlLabel value="CETRAN" control={<Radio />} label="Recurso ao CETRAN" />
              </RadioGroup>
            </FormControl>
            <FormControl style={{ marginTop: 10 }} fullWidth required>
              <FormLabel id="demo-radio-buttons-group-label">Qual o tipo de processo?</FormLabel>
              <RadioGroup
                style={{ marginLeft: 5 }}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                onClick={() => onSendEvent()}
                onChange={ (e) => handleProcess(e)}
              >
                <FormControlLabel value="SUSPENSION" control={<Radio />} label="Suspensão da CNH" />
                <FormControlLabel value="CANCELLATION" control={<Radio />} label="Cassação da CNH" />
              </RadioGroup>
            </FormControl>
            {processType === 'SUSPENSION' && (
              <FormControl style={{ marginTop: 10, marginBottom: 10 }} fullWidth required>
                <FormLabel id="demo-radio-buttons-group-label">Escolha o tipo de suspensão</FormLabel>
                <RadioGroup
                  style={{ marginLeft: 5 }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  onClick={() => onSendEvent()}
                  onChange={ (e) => handleSuspension(e)}
                >
                  <FormControlLabel value="POINTS" control={<Radio />} label="Suspensão por pontuação" />
                  <FormControlLabel value="INFRINGEMENT" control={<Radio />} label="Suspensão por infração específica" />
                </RadioGroup>
              </FormControl>
            )}
            {suspension === 'INFRINGEMENT' && (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={inciseds}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} label="Infrações suspensivas" />}
                onChange={handleSuspensionInfrigementChange}
                onClick={() => onSendEvent()}
              />
            )}
          </Box>
          {suspensionInfrigement !== null && (<CardDataInfo suspensionInfrigement={suspensionInfrigement} />)}
        </div>
      <div style={{ marginTop: '1rem' }} className="row mb-5">
        <h3 className="text-[25px] md:text-[40px]relative z-10">
          Dados do condutor a serem utilizados no recurso
        </h3>
      </div>
      <ContentCard>
        <div>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <div>
              <TextField
                label="CPF / CNPJ"
                id="outlined-start-cpfCnpj"
                onChange={handleChange('cpfCnpj')}
                sx={{ m: 1, width: '25ch' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                value={values.cpfCnpj}
                placeholder="Digite o CPF ou CNPJ"
                error={errorCpfCnpj}
                required
              />
              <TextField
                label="RG"
                id="outlined-start-rg"
                onChange={handleChange('rg')}
                sx={{ m: 1, width: '25ch' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                value={values.rg}
                placeholder="Digite o RG"
                error={errorRg}
                required
              />
              <TextField
                label="Orgão expedidor"
                id="outlined-start-agency"
                onChange={handleChange('agency')}
                sx={{ m: 1, width: '25ch' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                value={values.agency}
                placeholder="Digite o orgão expedidor"
                error={errors.errorAgency}
              />
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-name">Nome</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-name"
                  onChange={handleChange('name')}
                  startAdornment={
                    <InputAdornment position="start">T</InputAdornment>
                  }
                  label="Nome"
                  value={values.name}
                  placeholder="Digite o nome"
                  error={errors.errorName}
                  required
                />
              </FormControl>
              <TextField
                label="Número do processo"
                id="outlined-start-pc_net"
                onChange={handleChange('pcNet')}
                sx={{ m: 1, width: '25ch' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                value={values.pcNet}
                placeholder="Digite o número do processo"
                error={errors.errorPcNet}
                required
              />
              <TextField
                label="Resgistro CNH"
                id="outlined-start-cnh"
                onChange={handleChange('cnh')}
                sx={{ m: 1, width: '25ch' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                value={values.cnh}
                placeholder="Digite o registro da CNH"
                error={errors.errorCnh}
                required
              />
              <TextField
                label="Data de nascimento"
                id="outlined-start-birthDate"
                onChange={handleChange('birthDate')}
                sx={{ m: 1, width: '25ch' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                value={values.birthDate}
                placeholder="Opcional"
              />
              <TextField
                label="Data da primeira habilitação"
                id="outlined-start-date_of_first_license"
                onChange={handleChange('dateOfFirstLicense')}
                sx={{ m: 1, width: '25ch' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                value={values.dateOfFirstLicense}
                placeholder="Opcional"
              />
            </div>
            <div>
              <TextField
                label="CEP"
                id="filled-start-cep"
                onChange={handleChange('cep')}
                sx={{ m: 1, width: '25ch' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                variant="filled"
                value={values.cep}
                // placeholder="Digite o CEP"
                error={errorCep}
                required
              />
              <TextField
                label="Número"
                id="filled-start-number"
                onChange={handleChange('number')}
                sx={{ m: 1, width: '25ch' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                variant="filled"
                value={values.number}
                // placeholder="Digite o número"
                error={errors.errorNumber}
                required
              />
              <TextField
                label="Bairro"
                id="filled-start-neighborhood"
                onChange={handleChange('neighborhood')}
                sx={{ m: 1, width: '25ch' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                variant="filled"
                value={values.neighborhood}
                // placeholder="Digite o bairro"
                error={errors.errorNeighborhood}
                required
              />
              <TextField
                label="Cidade"
                id="filled-start-city"
                onChange={handleChange('city')}
                sx={{ m: 1, width: '25ch' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                variant="filled"
                value={values.city}
                // placeholder="Digite a cidade"
                error={errors.errorCity}
                required
              />
              <FormControl fullWidth sx={{ m: 1 }} variant="filled"  required>
                <InputLabel htmlFor="filled-adornment-street">
                  Rua / Endereço
                </InputLabel>
                <FilledInput
                  id="filled-adornment-street"
                  onChange={handleChange('street')}
                  startAdornment={<InputAdornment position="start" />}
                  value={values.street}
                  // placeholder="Digite a rua"
                  error={errors.errorStreet}
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }} variant="filled"  required>
                <InputLabel htmlFor="filled-adornment-complement">
                  Complemento
                </InputLabel>
                <FilledInput
                  id="filled-adornment-complement"
                  onChange={handleChange('complement')}
                  startAdornment={<InputAdornment position="start" />}
                  value={values.complement}
                  // placeholder="Digite o complemento"
                  error={errors.errorComplement}
                />
              </FormControl>
            </div>
            <div />
          </Box>
        </div>
      </ContentCard>
      <div style={{ marginTop: '2rem' }} className="row mb-5">
        <h3 className="text-[25px] md:text-[40px]relative z-10">
          Dados para acesso e envio do recurso
        </h3>
      </div>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-name">
              Nome
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  {!values.email && 'T'}
                </InputAdornment>
              }
              label="Name"
              // placeholder="Digite aqui o seu nome"
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-email">
              Email
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  {!values.email && '@'}
                </InputAdornment>
              }
              label="Email"
              // placeholder="O recurso será enviado para esse email"
              error={errorEmail}
              helperText={errorEmail ? 'Formato de email inválido' : ''}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              label="Telefone"
              id="outlined-start-phone"
              // sx={{  width: '45ch' }}
              onChange={handleChange('phone')}
              value={values.phone}
              inputProps={{
                maxLength: 15,
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start" />,
              }}
              placeholder="(00) 000000000"
              error={errorPhone}
              helperText={errorPhone ? 'Formato de telefone inválido' : ''}
            />
          </FormControl>
          <FormGroup style={{ marginLeft: 5 }}>
            <FormControlLabel
              style={{ marginLeft: 10 }}
              control={<Checkbox defaultChecked />}
              sx={{
                '& .css-ahj2mt-MuiTypography-root': {
                  fontSize: 11,
                },
              }}
              value={checkedTerm}
              onChange={() => setCheckedTerm(!checkedTerm)}
              label="Li e estou de acordo com os TERMOS E CONDIÇÕES DE USO."
            />
            <FormControlLabel
              style={{ marginLeft: 10 }}
              control={<Checkbox defaultChecked />}
              sx={{
                '& .css-ahj2mt-MuiTypography-root': {
                  fontSize: 11,
                },
              }}
              value={checkedTerm}
              onChange={() => setCheckedMails(!checkedMails)}
              label="Aceito receber emails de notificação de uso e serviços do Cancela Multas."
            />
            <ButtonBack onClick={ e => verifyToPayment()}>
              <LabelButton >Selecionar método de pagamento</LabelButton>
            </ButtonBack>
          </FormGroup>
          
        </Box>
        <Alert
          color="warning"
          className="rounded"
          isOpen={!!message}
          toggle={() => setMessage(!!message)}
        >
          {message}
        </Alert>
          <>
          {/* <div className="row mb-5">
            <h3 className="text-[25px] md:text-[40px]relative z-10">
              Selecione o método de pagamento
            </h3>
          </div> */}
          <AreaValue>
            <AreaItemPrice>
              De
              &ensp;
              <TextOriginal>
                <del>
                  R$ 399,00
                </del>
              </TextOriginal> 
              &ensp;
              Por
              &ensp;
            </AreaItemPrice>
            <ItemPrice>
                <Text>
                  R$ 99,00
                </Text>
                <LegendPrice>Valor promocional por tempo limitado!</LegendPrice>
            </ItemPrice>
          </AreaValue>
          <ContentCard>
            <AreaPayment>
              <Item onClick={() => handlePaymentMethod('CREDIT')}>
                <input style={{ margin: 5 }} type="radio" name="gender" value="female" class="mr-2" checked={paymentMethod === 'CREDIT' ? true : false} />
                <img
                  src={paymentMethod === 'CREDIT' ? CreditActive : Credit}
                  alt="credit"
                />
                <Description
                  style={{
                    color: paymentMethod === 'CREDIT' ? '#4caf50' : '#8F8F8F',
                  }}
                >
                  Crédito
                </Description>
              </Item>
              <Item onClick={() => handlePaymentMethod('DEBIT')}>
                <input style={{ margin: 5 }} type="radio" name="gender" value="female" class="mr-2" checked={paymentMethod === 'DEBIT' ? true : false}  />
                <img
                  src={paymentMethod === 'DEBIT' ? DebitActive : Debit}
                  alt="debit"
                />
                <Description
                  style={{
                    color: paymentMethod === 'DEBIT' ? '#4caf50' : '#8F8F8F',
                  }}
                >
                  Débito
                </Description>
              </Item>
              <Item onClick={() => handlePaymentMethod('PIX')}>
                <input  style={{ margin: 5 }} type="radio" name="gender" value="female" class="mr-2" checked={paymentMethod === 'PIX' ? true : false}  />
                <img src={paymentMethod === 'PIX' ? PixActive : Pix} alt="pix" />
                <Description
                  style={{
                    color: paymentMethod === 'PIX' ? '#4caf50' : '#8F8F8F',
                  }}
                >
                  Pix
                </Description>
              </Item>
              <Item onClick={() => handlePaymentMethod('BILLET')}>
                <input style={{ margin: 5 }} type="radio" name="gender" value="female" class="mr-2" checked={paymentMethod === 'BILLET' ? true : false}  />
                <img
                  src={paymentMethod === 'BILLET' ? BarcodeActive : Barcode}
                  alt="billet"
                />
                <Description
                  style={{
                    color: paymentMethod === 'BILLET' ? '#4caf50' : '#8F8F8F',
                  }}
                >
                  Boleto
                </Description>
              </Item>
            </AreaPayment>
          </ContentCard>
          
     
          <ContentCard>
            {loading && <ColoredLinearProgress />}
            <AreaButton>
              {enableButton(values) &&
              !errorCpfCnpj &&
              phase !== '' &&
              processType &&
              suspension &&
              paymentMethod &&
              !errorCep ? (
                <>
                  <Button
                    style={{
                      width: '100%',
                      margin: 5,
                      background: '#4caf50',
                      color: 'white',
                    }}
                    variant="contained"
                    onClick={() => onContinue(false)}
                  >
                    {loading && (
                      <CircularProgress size={14} style={{ color: 'white' }} />
                    )}
                    {!loading && 'Ir para página de pagamento'}
                  </Button>
                  <Button
                    style={{
                      width: '100%',
                      margin: 5,
                      background: '#4caf50',
                      color: 'white',
                    }}
                    variant="contained"
                    onClick={() => onContinue(true)}
                  >
                    {loading && (
                      <CircularProgress size={14} style={{ color: 'white' }} />
                    )}
                    {!loading && copyLink && copyLink.toLocaleLowerCase()}
                    {!loading && !copyLink && `Copiar link de pagamento`}
                  </Button>
                </>
              ) : (
                <>
                  <ButtonContinueHidden>
                    <LabelButtonContinue>
                      Ir para página de pagamento
                    </LabelButtonContinue>
                  </ButtonContinueHidden>
                  <ButtonContinueHidden>
                    <LabelButtonContinue>
                      Copiar link de pagamento
                    </LabelButtonContinue>
                  </ButtonContinueHidden>
                </>
              )}
            </AreaButton>
          </ContentCard>
          </>
      </div>
    </section>
    <FooterTwo />
    </>
  );
};

export default React.memo(PersonalData);
