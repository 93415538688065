import React, { useEffect } from "react";
import { NavLink, useLocation, NavNavLink, matchPath } from "react-router-dom";
import logo from "../../../src/assets/images/logo-red.png";

const Header = () => {
  const location = useLocation();

  const menuActive = () => {
    const menu = document.querySelector(".mobile-menu");
    menu.classList.toggle("hidden");
  };

  return (
    <div>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light fixed w-full horizontal-nav">
          <div className="container">
            <div className="flex items-center justify-between">
              <NavLink className="navbar-brand" to="/inicio/">
                <img src={logo} className="mr-2 w-[13%]" alt="logo" />
              </NavLink>
              <button
                onClick={() => menuActive()}
                className="navbar-toggler hidden text-end mobile-menu-button"
              >
                <span className="navbar-toggler-icon">
                  <i className="ti-menu icon-align-right"></i>
                </span>
              </button>

              <div
                className="navbar-collapse flex-1 mobile-menu hidden min-[991px]:block"
                id="navbarNav"
              >
                <ul className="navbar-nav justify-end horizontal-menu">
                  <li className="scroll">
                    <NavLink to="/inicio/">
                      <span>Página inicial</span>
                    </NavLink>
                  </li>
                  {/* <li className="scroll dropdown group/menu">
                    <NavLink
                      className={({ isActive }) => {
                        return location.pathname.match("/landing")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Landing</span>
                    </NavLink>
                    <ul className="dropdown-menu hidden group-hover/menu:block">
                      <li>
                        <NavLink to="/landing/">Overview</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page1">Landing Page 1</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page2">Landing Page 2</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page3">Landing Page 3</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page4">Landing Page 4</NavLink>
                      </li>
                    </ul>
                  </li> */}
                  <li className="scroll dropdown group/menu">
                    <NavLink
                      className={({ isActive }) => {
                        return location.pathname.match("/pages")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>A empresa</span>
                    </NavLink>
                    <ul className="dropdown-menu hidden group-hover/menu:block">
                      <li>
                        <NavLink to="/pages/">Sobre o Cancela Multas</NavLink>
                      </li>
                      {/* <li>
                        <NavLink to="/pages/servicies">Service</NavLink>
                      </li>
                      <li className="dropdown group/submenu">
                        <NavLink
                          className={({ isActive }) => {
                            return location.pathname.match("/pages/widget")
                              ? "active dropdown-toggle"
                              : "dropdown-toggle";
                          }}
                        >
                          Widget
                        </NavLink>
                        <ul className="dropdown-menu hidden group-hover/submenu:block">
                          <li>
                            <NavLink to="/pages/widget/">Pricing</NavLink>
                          </li>
                          <li>
                            <NavLink to="/pages/widget/testimonials">
                              Testimonial
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/pages/widget/faq">FAQs</NavLink>
                          </li>
                        </ul>
                      </li> */}
                      <li>
                        <NavLink to="/pages/contact">Contato</NavLink>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="scroll dropdown group/menu">
                    <NavLink
                     
                      className={({ isActive }) => {
                        return location.pathname.match("/account")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Minha conta</span>
                    </NavLink>
                    <ul className="dropdown-menu hidden group-hover/menu:block">
                      <li>
                        <NavLink to="/account/">Login</NavLink>
                      </li>
                      <li>
                        <NavLink to="/account/register">Registrar</NavLink>
                      </li>
                      <li>
                        <NavLink to="/account/pwreset">Recuperar senha</NavLink>
                      </li>
                    </ul>
                  </li> */}
                  <li className="scroll dropdown group/menu">
                    <NavLink to="/faq">
                      <span>Perguntas e respostas</span>
                    </NavLink>
                  </li>
                  {/* <NavLink to={`/account/register`}>
                    <button style={{ backgroundColor: 'white', color: '#072114' }} className="btn btn-sm btn-custom navbar-btn btn-rounded">
                      Criar conta
                    </button>
                  </NavLink> */}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
