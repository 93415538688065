import React, { useEffect, useState, useCallback } from "react";
import FooterTwo from "../components/footers/FooterTwo";
import Features from "../features/landingpage-two/Features";
import GetStarted from "../features/common/GetStarted";
import { Link } from "react-router-dom";
import Api from '../services/api';
import { saveSession } from "../helpers/Utils";

const LandingPageTwo = () => {
  const api = Api();
  const sessionId = saveSession()

  const [dataBlock] = useState({
    title: "RECEBEU UM PROCESSO ADMINISTRATIVO POR INFRAÇÃO DE TRÂNSITO? A CNH FOI CASSADA? Crie o documento para o seu recurso em minutos!",
    subTitle: "Com os nossos recursos você pode continuar dirigindo.",
    since: "Evite o processo de suspensão/cassação da sua CNH",
  });
  const [phase, setPhase] = useState('');

  const onSendEvent = useCallback(async () => {
    await api.post('/view-event', {
      session_id: sessionId.id,
      type_view: 'START_EVENT'
    });
  }, [api, sessionId]);
  
  useEffect(() => {
    onSendEvent();
  }, [onSendEvent]);

  const handleChange = (e) => {
    setPhase(e.target.value);
  };

  function handleClickAction(event) {
    // const specificLink = 'https://www.profitablegatecpm.com/y7w393rdg?key=bde7024d32c0c023d3b89faa87031596';
    // window.open(specificLink, '_blank');
    window.location.href = `/criar-recurso-para-processo-administrativo?phase=${phase}`;
  }
  
  function handleClickWhatsapp(event) {
    window.location.href = ` https://api.whatsapp.com/send/?phone=5531982911882&text&type=phone_number&app_absent=0`;
  }

  return (
    <>
      <amp-auto-ads type="adsense"
        data-ad-client="ca-pub-7331339675634058">
      </amp-auto-ads>
      <section className="md:pt-[160px] md:pb-[170px] pt-[100px] pb-[120px] relative bg-cover bg-center bg-themedark">
        <div className="absolute inset-0 bg-cover bg-center bg-no-repeat w-full h-full bg-[url('../images/home_sec_layer-2.png')]"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 lg:grid-cols-5 items-center gap-[30px]">
            <div className="lg:col-span-3">
              <p className="py-2.5 px-5 bg-white/[0.03] italic inline-block rounded-lg text-custom mb-4 md:mb-0">
                {dataBlock.since}
              </p>
              <h1 className="mt-4 md:text-[40px] text-[34px] leading-[1.2] text-white">
                {dataBlock.title}
              </h1>
              <p className="mt-6 text-lg text-white/40">{dataBlock.subTitle}</p>
                {/* <div className="btn-custom rounded-none mt-12 flex space-x-4">
                  <Link onClick={handleClickWhatsapp}>
                    RECORRA AGORA
                  </Link>
                  <Link
                    to="#"
                    className="text-white w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                  >
                    <i className="mdi mdi-whatsapp"></i>
                  </Link>
                </div> */}

                <div className="mt-12 flex space-x-4">
                  <Link
                    onClick={handleClickWhatsapp}
                    className="text-white w-[78px] h-[78px] leading-[78px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                  >
                    <i className="mdi mdi-whatsapp" style={{ fontSize: '48px', width: '100%' }}></i>
                  </Link>
                </div>

              <div className="bg-white/[8%] w-full h-px block my-10 md:my-[50px]"></div>
        
            </div>
            <div className="lg:col-span-2">
              <div className="bg-white py-10 px-5 md:py-[60px] md:px-[30px] rounded">
                <h2 className="text-center font-bold text-[32px]">
                  Teses com alto índice de aceitação
                </h2>
                <p className="text-muted text-center mt-4">
                  Nossas teses são elaboradas por profissionais especialistas em trânsito.
                </p>
                <form action="" className="mt-12 space-y-4">
                  <div>
                    <label class="flex items-center">
                      <input onChange={handleChange} type="radio" name="gender" value="PREVIEW" class="mr-2" />
                      Portaria inaugural / Defesa prévia
                    </label>
                  </div>
                  <div>
                    <label class="flex items-center">
                      <input onChange={handleChange} type="radio" name="gender" value="JARI" class="mr-2" />
                      JARI (Junta Administrativa de Recursos de Infrações).
                    </label>
                  </div>
                  <div>
                    <label class="flex items-center">
                      <input onChange={handleChange} type="radio" name="gender" value="CETRAN" class="mr-2" />
                      CETRAN (Conselho Estadual de Trânsito).
                    </label>
                  </div>
                  {/* <Link to={`/criar-recurso-para-processo-administrativo?phase=${phase}`}>
                    <button className="btn-custom rounded-none !mt-8 w-full" type="submit">
                      Continuar
                    </button>
                  </Link> */}
                  <Link onClick={handleClickAction}>
                    <button className="btn-custom rounded-none !mt-8 w-full" type="submit">
                      Continuar
                    </button>
                  </Link>
                </form>
              </div>
       
            </div>
            <div className="sm:space-y-0 space-y-4">
              <div className="items-center sm:inline-block block">
                <span className="text-muted" style={{ color: 'white' }}>Passo a passo para você criar o seu recurso no Cancela Multas:</span>
              </div>
              {/* <div className="inline-block">
                <img
                  src={client6}
                  alt="client6"
                  className="h-[26px] my-2 mx-3 opacity-70 inline-block"
                />
                <img
                  src={client7}
                  alt="client7"
                  className="h-[26px] my-2 mx-3 opacity-70 inline-block"
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <Features />
      <GetStarted />
      {/* <FunfactVideo/>
      <RightPlan/>
      <Counter/>
      <Coustomer/> */}
      {/* <ClientLogo/> */}
      <FooterTwo/>
    </>
  );
};

export default LandingPageTwo;
