import React from "react";
import logoLight from "../../assets/images/bandeiras.png";
import sslImage from "../../assets/images/ssl.png";
import img1 from '../../assets/images/icon/check.svg'
import { Link } from "react-router-dom";

const FooterTwo = () => {
  return (
    <>
      <footer className="bg-themedark pt-20 pb-10">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-6 gap-[30px] items-start">
            <div className="order-2 lg:order-1 mt-4 md:mt-0 md:col-span-2 lg:col-auto" >
              <img
                src={logoLight}
                alt="logo light"
                className="img-fluid w-[230px] block text-md-left text-sm-left"
              />
              <img
                src={sslImage}
                alt="logo light"
                className="img-fluid w-[130px] block text-md-left text-sm-left"
              />
            </div>
            <div className="order-2 lg:order-1 md:col-span-2 lg:col-auto">
              <h6 className="text-white text-lg">Help center</h6>
              <div className="w-7 h-[2px] bg-white/20 mt-2"></div>
              <ul className="mt-6 text-white/40 space-y-2.5 text-sm">
                <li>
                  <Link
                    to="/pages"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Sobre nós
                  </Link>
                </li>
                <li>
                  <Link
                    to="/faq"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Dúvidas
                  </Link>
                </li>
                <li>
                  <Link
                    to="/pages/contact"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Entrar em contato
                  </Link>
                </li>
              </ul>
              <h6 style={{ marginTop: '1rem' }} className="text-white text-lg">Principais multas</h6>
              <div className="w-7 h-[2px] bg-white/20 mt-2"></div>
              <ul className="mt-6 text-white/40 space-y-2.5 text-sm">
                <li>
                  <Link
                    target="_blank"
                    to="https://lexvia.com.br/lei-seca-tudo-o-que-voce-precisa-saber/"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Lei seca - tudo o que precisa saber
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://lexvia.com.br/ppd-permissao-para-dirigir/"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Acidente de trânsito
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://lexvia.com.br/embriaguez-ao-volante/"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Embriaguês ao volante
                  </Link>
                </li>
              </ul>
            </div>
            <div className="order-2 lg:order-1 md:col-span-2 lg:col-auto">
              <h6 className="text-white text-lg">Políticas da empresa</h6>
              <div className="w-7 h-[2px] bg-white/20 mt-2"></div>
              <ul className="mt-6 text-white/40 space-y-2.5 text-sm">
                <li>
                  <Link
                    target="_blank"
                    to="https://drive.google.com/file/d/1pDhFJZJFtnyM6zmsXJyLHc1QPlajuBGg/view?usp=sharing"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Política de privacidade
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://drive.google.com/file/d/1tHlKfpTDNBnaA9oY3xHLRn9qzUDhKfgw/view?usp=sharing"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Política de cookies
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://drive.google.com/file/d/1th-1vNSd-fQ68k86hVTDw4uDG6OGQjqi/view?usp=sharing"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Termos de uso
                  </Link>
                </li>
              </ul>
              <h6 style={{ marginTop: '1rem' }} className="text-white text-lg">Links fáceis</h6>
              <div className="w-7 h-[2px] bg-white/20 mt-2"></div>
              <ul className="mt-6 text-white/40 space-y-2.5 text-sm">
                <li>
                  <Link
                    target="_blank"
                    to="https://www.gov.br/pt-br"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    GOV / Serviços e Informações do Brasil
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.gov.br/pt-br/servicos/indicar-online-o-principal-condutor-de-um-veiculo"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Indicar condutor online
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.planalto.gov.br/ccivil_03/leis/l9503compilado.htm"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    CTB - Código de Trânsito Brasileiro
                  </Link>
                </li>
              </ul>
            </div>
            <div className="lg:col-span-3 md:col-span-6 order-1 lg:order-2">
              <div className="bg-custom rounded max-w-[460px] mx-auto relative py-10 px-5 -mt-[140px]">
                <div className="text-left">
                  <h3 className="font-bold text-[20px] text-white md:text-[30px] mb-2 relative z-10">
                    Recurso pronto em poucos minutos!
                  </h3>
                  <p className="text-white mx-auto text-lg max-w-[600px] mt-6">
                  <img
                    src={img1}
                    className="h-[26px] mx-auto inline-block"
                    alt="..."
                  />
                    Recurso para todas as intâncias
                  </p>
                  <p className="text-white mx-auto text-lg max-w-[600px] mt-6">
                    <img
                      src={img1}
                      className="h-[26px] mx-auto inline-block"
                      alt="..."
                    />
                    Multas DER
                  </p>
                  <p className="text-white mx-auto text-lg max-w-[600px] mt-6">
                    <img
                      src={img1}
                      className="h-[26px] mx-auto inline-block"
                      alt="..."
                    />
                    Multas  DETRAN
                  </p>
                </div>
                {/* <form className="mt-12 space-y-4">
                  <input
                    type="email"
                    className="form-input border border-solid h-[50px] rounded inline-block !border-white/[31%] bg-white/[15%] text-white placeholder:text-white/60"
                    placeholder="seu email.."
                    required=""
                  />
                  <button type="submit" className="btn-white rounded-[5px] w-full">
                    Enviar
                  </button>
                </form> */}
              </div>
            </div>
          </div>
          <div className="bg-white/[7%] h-px w-full mt-12 mb-8"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[30px]">
            <div>
              <p className="mb-0 text-white/50 text-center md:text-left">
                &copy;Cancela Multas. Todos os direitos reservados - 2024. R dos Timbiras, 3156  sala 402 Barro Preto, Belo Horizonte MG 30.140-062 - CNPJ 48.779.883/0001-06
              </p>
            </div>
            <div>
              <ul className="flex items-center space-x-3 md:justify-end justify-center">
                <li>
                  <Link
                    target="_blank"
                    to="https://www.facebook.com/cancelamultasbr"
                    className="text-white w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                  >
                    <i className="mdi mdi-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.instagram.com/cancelamultas/"
                    className="text-white w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                  >
                    <i className="mdi mdi-instagram"></i>
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="https://www.instagram.com/cancelamultas/"
                    className="text-white w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                  >
                    <i className="mdi mdi-reddit"></i>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterTwo;
