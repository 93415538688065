/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Alert } from 'reactstrap';
import FooterTwo from "../components/footers/FooterTwo";
import Api from '../services/api';
import ColoredLinearProgress from '../common/LineProgress';
import Features from "../features/landingpage-two/Features-download";
import Icon from '../assets/images/info.svg'


const Content = styled.div`
  margin: 100px 90px 30px 90px;
  padding: 0px 70px 0px 70px;

  @media only screen and (max-width: 1400px) {
    margin: 70px 40px 30px 40px;
    padding: 0px 20px 0px 20px;
  }

  @media only screen and (max-width: 900px) {
    margin: 50px 10px 30px 10px;
    padding: 0px 20px 0px 20px;
  }
`;

const ContentCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-beteween;

  @media only screen and (max-width: 880px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

const AreaForm = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  background: white;

  @media only screen and (max-width: 880px) {
    flex-direction: column;
  }
`;

const Item = styled.div`
  width: 100%;
  text-align: left;
`;

const Text = styled.div`
  color: #242424;
  font-weight: 900;
`;

const Legend = styled.div`
  color: #c2c2c2;
  font-weight: 900;
  font-size: 12px;
`;

const Register = styled.a`
  background-color: #07e7ccf2;
  border-color: transparent;
  color: #fff;
  border-radius: 8px !important;
  height: auto !important;
  border-width: 1px;
  color: white !important;
  cursor: pointer;
  justify-content: center;
  padding: calc(0.5em - 1px) 1em;
  text-align: center;
  border: 1px solid transparent;
  align-items: center;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  line-height: 1.5;
  vertical-align: top;
`;

const ButtonBack = styled.div`
  box-sizing: border-box;
  width: 50%;
  border: 2px solid #4caf50;
  border-radius: 4px;
  justify-content: center;
  margin: 5px;
  padding: 1rem;
  cursor: pointer;
  color: black;
  text-align: center;
`;

const LabelButton = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #1d6fb6;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const AreaButtonHome = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5;
`;

const AreaNotification = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Notification = styled.div`
  margin-left: 15px;
  text-align: center;
  color: black;
`;

const Download = () => {
  const api = Api();

  const [message, setMessage] = useState(null);
  const [urlDownload, setUrlDownload] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onContinue();
  }, []);

  async function onContinue() {
    const resource_id = localStorage.getItem('resource_id');

    if (resource_id) {
      setLoading(true);
      api
        .get(`/resource/${resource_id}`)
        .then((response) => {
          if (response.data.error) {
            setMessage(
              response.data.message
                ? response.data.message
                : 'Error ao consultar recurso, tente novamente!'
            );
          }
          if (!response.data.data.error) {
            console.log('Res: resource', response.data);
            setMessage(null);
            setUrlDownload(response.data.data.data.download);
            setFileName(response.data.data.data.file_name);
            setId(response.data.data.data.resource_id);
            setTimeout(() => setLoading(false), 1000);
          }
        })
        .catch((error) => {
          setMessage(
            error?.response?.data?.data?.message
              ? error.response.data.data.message
              : 'Ocorreu um erro inesperado!'
          );

          setTimeout(() => setLoading(false), 2000);
        });
    } else {
      setMessage('Nenhum recurso para verificar, procure um atendente pelo chat!');
    }
  }

  function handleDownload() {
    window.location.replace(urlDownload);
  }

  return (
    <>
      <section className="md:pt-[170px] pt-[120px] pb-20 md:pb-[90px] bg-cover bg-[url(../images/bg-pages.jpg)] relative">
        <div className="container">
          <div className="grid grid-cols-1">
            <h1 className="font-bold text-[36px]">Baixar recurso em PDF</h1>
            <p className="mt-2">Isso pode levar alguns segundos!</p>
          </div>
        </div>
      </section>
      <section className="md:pt-[20px] pt-[20px] pb-20 md:pb-[20px] bg-cover bg-[url(../images/bg-pages.jpg)] relative">
     <div className="container">
      <Content>
        {loading && <ColoredLinearProgress style={{ marginBottom: '0rem'}} />}
        {!!message && !loading && (
          <AreaNotification>
            <img src={Icon} alt=""/>
            <Notification>
              ID de recurso { localStorage.getItem('resource_id') }
            </Notification>
          </AreaNotification>
        )}
        <Alert
          color="info"
          className="rounded"
          isOpen={!!message && !loading}
          toggle={() => setMessage(!!message)}
          style={{ marginBottom: '2rem' }}
        >
          {message}
        </Alert>
        {!!message && !loading && (
          <AreaButtonHome>
            <ButtonBack onClick={() => onContinue()}>
              Verificar novamente
            </ButtonBack>
          </AreaButtonHome>
        )}
        {urlDownload && (
          <>
            <ContentCard>
              <AreaForm>
                <Item>
                  <Text>{ id }CM</Text>
                  <Legend>ID do recurso</Legend>
                </Item>
                <Item>
                  <Text>{ fileName }</Text>
                  <Legend>Nome do arquivo</Legend>
                </Item>
                <Item>
                  <Text>PDF</Text>
                  <Legend>Tipo do arquivo</Legend>
                </Item>
                <Item  onClick={
                  () => handleDownload()
                } >
                    <Register>Baixar arquivo</Register>
                </Item>
              </AreaForm>
            </ContentCard>
            <AreaButtonHome>
              <ButtonBack>
                <NavLink to="/criar-recurso-para-processo-administrativo?phase=">
                  <LabelButton style={{ color: 'black' }}>Criar outro recurso</LabelButton>
                </NavLink>
              </ButtonBack>
            </AreaButtonHome>
          </>
        )}
      </Content>
      </div>
      </section>
      <Features />
      <FooterTwo />
    </>
  );
};

export default React.memo(Download);
