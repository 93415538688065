import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from "../assets/images/logo-red.png";


const Login = () => {

    const [dataBlock] = useState({
        title:"Fazer login",
        subTitle:"Faça login em sua conta usando o e-mail e a senha fornecidos durante o registro."
    })

  return (
    <>
         <section className="items-center flex bg-[url('../images/login_bg.jpg')] bg-cover bg-center relative py-20 min-h-screen">
        <div className="bg-[#0d0f14]/25 absolute inset-0 backdrop-blur-md"></div>
        <div className="container relative">
            <div className="grid grid-cols-1">
                <div className="max-w-[650px] mx-auto">
                    <Link to="/inicio">
                        <img src={logo} alt="logo" className="mx-auto h-20"/>
                    </Link>
                </div>
            </div>
            <div className="grid grid-cols-1 mt-16">
                <div className="max-w-[450px] mx-auto py-10 px-8 rounded-xl bg-white w-full">
                    <h4 className="font-bold text-2xl mb-2 text-center capitalize">{dataBlock.title}</h4>
                    <p className="text-muted text-center">{dataBlock.subTitle}</p>
                    <form className="mt-6 space-y-4">
                        <div>
                            <label className="font-bold">Email</label>
                            <input type="text" className="form-input mt-2 rounded !bg-white border border-[#ced4da] text-base focus:border-custom" placeholder="Email"/>
                        </div>
                        <div>
                            <label className="font-bold">Password</label>
                            <input type="password" className="form-input mt-2 rounded !bg-white border border-[#ced4da] text-base focus:border-custom" placeholder="Password"/>
                        </div>
                        <div>
                            <input type="checkbox" className="form-checkbox border-[#ced4da] rounded focus:ring-transperent focus:outline-none h-4 w-4 text-custom" id="sign"/>
                            <label htmlFor="sign" className="ml-1 relative top-[2px]">Mantenha conectado</label>
                        </div>
                        <div>
                            <button type="submit" className="btn-custom w-full">Acessar</button>
                        </div>
                    </form>
                    <div className="mt-4 text-center">
                        <Link to="/account/pwreset" className="font-bold">Esqueceu a senha?</Link>
                    </div>
                </div>
                <div className="text-center mt-6">
                    <p><small className="text-white mr-2">Não tem uma conta?</small> <Link to="/account/register" className="text-white font-bold">Criar conta</Link></p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Login
