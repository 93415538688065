import React, {useState} from 'react';
import styled from 'styled-components';

const Content = styled.div`
  margin: 30px 0px 70px 0px;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 30px;

  @media only screen and (max-width: 300px) {
    display: none;
  
`;

const ContentCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-beteween;

  @media only screen and (max-width: 880px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

const AreaForm = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Item = styled.div`
  width: 100%;
  text-align: left;
`;

const Text = styled.div`
  color: #242424;
  font-weight: 900;
`;

const TextRed = styled.div`
  color: #ab0000;
  font-weight: 900;
`;

const Legend = styled.div`
  color: #c2c2c2;
  font-weight: 900;
  font-size: 12px;
`;

const LegendRed = styled.div`
  color: #a58585;
  font-weight: 900;
  font-size: 12px;
`;

const CardDataInfo = ({ suspensionInfrigement }) => {
  const [hasInfo, setHasInfo] = useState(true);

  return (
    <>
      {hasInfo ? (
        <Content>
          <ContentCard>
            <AreaForm>
              <Item>
                <Text>
                  {suspensionInfrigement.infringement_article.toUpperCase()}
                </Text>
                <Legend>Artigo</Legend>
              </Item>
              <Item>
                <TextRed>
                  {suspensionInfrigement.points}
                </TextRed>
                <LegendRed>Pontos</LegendRed>
              </Item>
              <Item>
                <TextRed>
                  {suspensionInfrigement.nature
                    .toUpperCase()
                    .replace('INFRAÇÃO', '')
                    .replace('-', '')}
                </TextRed>
                <LegendRed>Infração</LegendRed>
              </Item>
            </AreaForm>
          </ContentCard>
          <Item>
            <Text>
              {suspensionInfrigement.code.toUpperCase()}
            </Text>
            <Legend>Código</Legend>
          </Item>
          <Item>
            <Text>
              {suspensionInfrigement.description.toUpperCase()}
            </Text>
            <Legend>Descrição</Legend>
          </Item>
          <Item>
            <TextRed>
              {suspensionInfrigement.penality.toUpperCase()}
            </TextRed>
            <LegendRed>Penalidade</LegendRed>
          </Item>
        </Content>
      ) : null}
    </>
  );
};

export default React.memo(CardDataInfo);
