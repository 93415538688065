const swiperdata = [
    {
        id:1,
        icon:"mdi mdi-format-quote-open",
        description: "“We all know the stigma around build times and the ever expanding arsenal of tooling in modern web apps.”",
        user:"Robert Goodwin",
        address:"California USA"
    },
    {
        id:2,
        icon:"mdi mdi-format-quote-open",
        description: "“We all know the stigma around build times and the ever expanding arsenal of tooling in modern web apps.”",
        user:"Robert Goodwin",
        address:"California USA"
    },
    {
        id:3,
        icon:"mdi mdi-format-quote-open",
        description: "“We all know the stigma around build times and the ever expanding arsenal of tooling in modern web apps.”",
        user:"Robert Goodwin",
        address:"California USA"
    },
    {
        id:4,
        icon:"mdi mdi-format-quote-open",
        description: "“We all know the stigma around build times and the ever expanding arsenal of tooling in modern web apps.”",
        user:"Robert Goodwin",
        address:"California USA"
    },
]


export default swiperdata