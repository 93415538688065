import React, { useState } from "react";
import feature1 from "../../assets/images/faq.jpg";
import icon1 from "../../assets/images/icon/features-1.png";
import icon2 from "../../assets/images/icon/features-2.png";
import icon3 from "../../assets/images/icon/features-3.png";

const SmartFeature = () => {

  return (
    <>
      <section className="py-20">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="text-center">
              <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                MULTAS
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
              NIC (NÃO IDENTIFICAÇÃO DE CONDUTOR), INFRAÇÕES GRAVES OU GRAVÍSSIMAS.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] mt-16 items-center">
            <div>
              <img
                src={feature1}
                alt="feature"
                className="img-fluid mx-auto d-block"
              />
            </div>
            <div className="mt-4">
              <h2 className="capitalize text-3xl leading-[1.4] max-w-[450px]">
              O motorista autuado devido a uma infração pode recorrer de multa de trânsito. Esse é um direito garantido a todos os condutores, a partir do que afirma a Constituição Federal, no inciso LV do seu art. 5º.
              </h2>
              <p className="text-muted mt-6 max-w-[500px] text-lg leading-[1.4] mb-0">
              O atraso pode acarretar na impossibilidade de se defender naquela instância, visto que recursos entregues fora da data não são avaliados.
              </p>
              <div className="mt-6 space-y-4">
                <div>
                  <div className="bg-custom/[0.15] inline-block h-[42px] w-[42px] leading-[42px] text-center rounded-[6px]">
                    <img
                      src={icon1}
                      alt="feature1"
                      className="mx-auto inline-block w-[26px]"
                    />
                  </div>
                  <span className="text-muted inline-block ml-2">
                    Aguardamos seu contato
                  </span>
                </div>
                <div>
                  <div className="bg-custom/[0.15] inline-block h-[42px] w-[42px] leading-[42px] text-center rounded-[6px]">
                    <img
                      src={icon2}
                      alt="icon1"
                      className="w-[26px] inline-block mx-auto"
                    />
                  </div>
                  <span className="text-muted ml-2">Oferecemos suporte</span>
                </div>
                <div>
                  <div className="bg-custom/[0.15] inline-block h-[42px] w-[42px] leading-[42px] text-center rounded-[6px]">
                    <img
                      src={icon3}
                      alt="icon2"
                      className="w-[26px] inline-block mx-auto"
                    />
                  </div>
                  <span className="text-muted ml-2">
                    Vamos te ajudar!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SmartFeature;
