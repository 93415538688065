const pricingplanPerMonth = [
    {
        id: 3,
        name:"Landik Starter",
        starArray: [1],
        description:"Nulla metus metus vel tincidunt sed euismod nibh volutpat.",
        price:"$19.99",
        subText:"Per Month",        
    },
    {
        id: 4,
        name:"Landik Enterprice",
        starArray: [1, 2],
        description:"Nulla metus metus vel tincidunt sed euismod nibh volutpat.",
        price:"$29.99",
        subText:"Per Month",        
    },
    {
        id: 5,
        name:"Landik Unlimited",
        starArray: [1, 2, 3],
        description:"Nulla metus metus vel tincidunt sed euismod nibh volutpat.",
        price:"$49.99",
        subText:"Per Month",        
    },
]

const pricingplanPerYear = [
    {
        id: 3,
        name:"Landik Starter",
        starArray: [1],
        description:"Nulla metus metus vel tincidunt sed euismod nibh volutpat.",
        price:"$199.99",
        subText:"Per Year",        
    },
    {
        id: 4,
        name:"Landik Enterprice",
        starArray: [1, 2],
        description:"Nulla metus metus vel tincidunt sed euismod nibh volutpat.",
        price:"$299.99",
        subText:"Per Year",        
    },
    {
        id: 5,
        name:"Landik Unlimited",
        starArray: [1, 2, 3],
        description:"Nulla metus metus vel tincidunt sed euismod nibh volutpat.",
        price:"$499.99",
        subText:"Per Year",        
    },
]

const pricingPlanPolicy = [
    {
        id:0,
        name:  "Monthly"
    },
    {
        id:1,
        name:  "Yearly"
    },
]

export {pricingplanPerMonth, pricingplanPerYear, pricingPlanPolicy}