import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo-red.png";

const Ragister = () => {
  const [dataBlock] = useState({
    title: "Criar conta!",
    subTitle:
      "O registro leva menos de um minuto, mas oferece controle total sobre seus pedidos.",
  });
  return (
    <>
      <section className="items-center flex bg-[url('../images/login_bg.jpg')] bg-cover bg-center relative py-20 min-h-screen">
        <div className="bg-[#0d0f14]/25 absolute inset-0 backdrop-blur-md"></div>
        <div className="container relative">
          <div className="grid grid-cols-1">
            <div className="max-w-[450px] mx-auto">
              <Link to="/inicio">
                <img
                  src={logo}
                  alt="logo"
                  className="mx-auto h-20"
                />
              </Link>
            </div>
          </div>
          <div className="grid grid-cols-1 mt-16">
            <div className="max-w-[450px] mx-auto py-10 px-8 rounded-xl bg-white w-full">
              <h4 className="font-bold text-2xl mb-2 text-center capitalize">
                {dataBlock.title}
              </h4>
              <p className="text-muted text-center">{dataBlock.subTitle}</p>
              <form className="mt-6 space-y-4">
                <div>
                  <label className="font-bold">Nome</label>
                  <input
                    type="text"
                    className="form-input mt-2 rounded !bg-white border border-[#ced4da] text-base focus:border-custom"
                    placeholder="Nome"
                  />
                </div>
                <div>
                  <label className="font-bold">Email</label>
                  <input
                    type="text"
                    className="form-input mt-2 rounded !bg-white border border-[#ced4da] text-base focus:border-custom"
                    placeholder="Email"
                  />
                </div>
                <div>
                  <label className="font-bold">Password</label>
                  <input
                    type="password"
                    className="form-input mt-2 rounded !bg-white border border-[#ced4da] text-base focus:border-custom"
                    placeholder="Password"
                  />
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="form-checkbox border-[#ced4da] rounded focus:ring-transperent focus:outline-none h-4 w-4 text-custom"
                    id="sign"
                  />
                  <label htmlFor="sign" className="ml-1 relative top-[2px]">
                    Eu aceito os {" "}
                    <a target="_blank" href="https://drive.google.com/file/d/1th-1vNSd-fQ68k86hVTDw4uDG6OGQjqi/view?usp=sharing" className="text-custom">
                      Termos & Política de Privacidade
                    </a>
                  </label>
                </div>
                <div>
                  <button type="submit" className="btn-custom w-full">
                    Criar conta
                  </button>
                </div>
              </form>
            </div>
            <div className="text-center mt-6">
              <p>
                <small className="text-white mr-2">Já possui uma conta?</small>
                <Link to="/account/" className="text-white font-bold">
                  Quero fazer login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ragister;
