import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AccountLayout from "./layout/AccountLayout";
import DocumentationLayout from "./layout/DocumentationLayout";
import LandingPageLayout from "./layout/LandingPageLayout";
import MainLayout from "./layout/MainLayout";
import PageLayout from "./layout/PageLayout";
import About from "./pages/About";
import ChangeLog from "./pages/ChangeLog";
import ContactPage from "./pages/ContactPage";
import Documentation from "./pages/Documentation";
import FAQPage from "./pages/FAQPage";
import LandingPageFour from "./pages/LandingPageFour";
import LandingPageOne from "./pages/LandingPageOne";
import LandingPageThree from "./pages/LandingPageThree";
import LandingPageTwo from "./pages/LandingPageTwo";
import Login from "./pages/Login";
import Overview from "./pages/Overview";
import PricingPage from "./pages/PricingPage";
import PWReset from "./pages/PWReset";
import Ragister from "./pages/Ragister";
import Servicies from "./pages/Servicies";
import Testimonials from "./pages/Testimonials";
import PersonalData from "./pages/PersonalData";
import Download from "./pages/Download";

const App = () =>  {
  
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "criar-recurso-para-processo-administrativo",
          element: <>
            <LandingPageLayout />,
            <PersonalData />
          </>,
        },
        {
          path: "download",
          element: <>
            <LandingPageLayout />,
            <Download />
          </>,
        },
        {
          path: "inicio",
          element: <>
            <LandingPageLayout />,
            <LandingPageTwo />
          </>,
        },
        {
          path: "pages",
          element: <PageLayout />,
          children: [
            {
              path: "",
              element: <About />,
            },
            {
              path: "servicies",
              element: <Servicies />,
            },
            {
              path: "widget",
              element: <PageLayout/>,
               children: [
                {
                  path: "",
                  element: <PricingPage />,
                },
                {
                  path: "testimonials",
                  element: <Testimonials />,
                },
                {
                  path: "faq",
                  element: <FAQPage />,
                },
              ]
            },
            {
              path: "contact",
              element: <ContactPage />,
            },
          ],
        },
        {
          path: "account",
          element: <AccountLayout />,
          children: [
            {
              path: "",
              element: <Login />,
            },
            {
              path: "register",
              element: <Ragister />,
            },
            {
              path: "pwreset",
              element: <PWReset />,
            },
          ],
        },
        {
          path: "faq",
          element: <>
            <DocumentationLayout />,
            <FAQPage />
          </>,
        },
      ],
    },
  ]);
  return <>
      <RouterProvider router={router} />
  </>
}

export default App;
