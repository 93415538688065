import axios from 'axios';

const Api = (content = 'application/json') => {
  const token = localStorage.getItem('access_token') || null;
  const api = axios.create({
    baseURL: `https://8lhs6gmka7.execute-api.us-east-1.amazonaws.com/prd/api/v1`,
  });

  api.interceptors.request.use(async (config) => {
    const configParam = config;
    configParam.headers['Content-Type'] = content;
    configParam.headers.Authorization = `Bearer ${token}`;

    return configParam;
  });

  return api;
};

export default Api;
