import React, { useState } from "react";
import aboutImg from "../../assets/images/about_com.jpg";
import { Link } from "react-router-dom";

const ContentSection = () => {
  const [designDataBlock] = useState({
    name: "Perfact Design",
    title: "It’s everything you’ll ever need.",
    description:
      " Create custom landing pages with Landik that converts more visitors than any website. With lots of unique blocks, you can easily build a page without coding.",
  });

  const [userDataBlock] = useState({
    name: "User Interface",
    title: "Unlimited Features Awaiting For You",
    description:
      " Create custom landing pages with Landik that converts more visitors than any website. With lots of unique blocks, you can easily build a page without coding.",
  });
  return (
    <>
      <section className="py-20 bg-white">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[30px]">
            <div className="max-w-[450px] mx-auto">
              <p className="[&>span]:bg-custom tracking-[6px] text-xs uppercase [&>span]:h-3 z-10 [&>span]:w-[60px] [&>span]:inline-block [&>span]:absolute [&>span]:opacity-10 relative ">
                <span></span>
                {designDataBlock.name}
              </p>
              <h3 className="font-bold text-[40px] capitalize mt-6">
                {designDataBlock.title}
              </h3>
              <p className="text-muted mt-6">{designDataBlock.description}</p>
              <div className="mt-10">
                <Link to="#" className="btn-custom rounded-full">
                  Explore
                </Link>
              </div>
            </div>
            <div>
              <img src={aboutImg} alt="com" className="mx-auto rounded-[30px]" />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[30px] mt-16">
            <div>
              <img src={aboutImg} alt="com" className="mx-auto rounded-[30px]" />
            </div>
            <div className="max-w-[450px] mx-auto">
              <p className="[&>span]:bg-custom tracking-[6px] text-xs uppercase [&>span]:h-3 z-10 [&>span]:w-[60px] [&>span]:inline-block [&>span]:absolute [&>span]:opacity-10 relative ">
                <span></span>
                {userDataBlock.name}
              </p>
              <h3 className="font-bold text-[40px] capitalize mt-6">
                {userDataBlock.title}
              </h3>
              <p className="text-muted mt-6">{userDataBlock.description}</p>
              <div className="mt-10">
                <Link to="#" className="btn-custom rounded-full">
                  Explore
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContentSection;
