const pricing = [
  {
    id: 1,
    planName: "Starter Plan",
    price: "$29",
    subText: "/Per Month",
    description:
      "Nulla metus metus vel tincidunt sed euismod nibh Quisque volutpat.",
    link: "Join Now",
    bestPlan: false,
  },
  {
    id: 2,
    planName: "Enterprice Plan",
    price: "$39",
    subText: "/Per Month",
    description:
      "Nulla metus metus vel tincidunt sed euismod nibh Quisque volutpat.",
    link: "Join Now",
    bestPlan: true,
  },
  {
    id: 3,
    planName: "Unlimited Plan",
    price: "$49",
    subText: "/Per Month",
    description:
      "Nulla metus metus vel tincidunt sed euismod nibh Quisque volutpat.",
    link: "Join Now",
    bestPlan: false,
  },
];


export default pricing