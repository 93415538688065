import image1 from '../../images/testi/testi-1.jpg'
import image2 from '../../images/testi/testi-2.jpg'
import image3 from '../../images/testi/testi-3.jpg'



const coustomer = [
    {
        id:1,
        image:image1,
        description:"“We cut our build times in half compared to our previous process. Love it.”",
        name:"Scott Ross",
        projectName:"Landik"
    },
    {
        id:2,
        image:image2,
        description:"“The support team is super helpful. We're so glad to have them on call.”",
        name:"Florence Bowen",
        projectName:"Landik"
    },
    {
        id:3,
        image:image3,
        description:"“Reliability is what Landik is known for, and they've totally delivered this time.”",
        name:"Joanne Boyles",
        projectName:"Landik"
    },
]

export default coustomer