const overviewData = [
    {
        id:1,
        type: "text",
        name: "Seu nome",
        placeHolder: "Seu nome...",
        class:"form-input bg-[#f8f9fa] border !border-[#edf0f3] focus:!border-custom"
    },
    {
        id:2,
        type: "email",
        name: "Seu Email",
        placeHolder: "Seu Email...",
        class:"form-input bg-[#f8f9fa] border !border-[#edf0f3] focus:!border-custom"
    },
    {
        id:3,
        type: "text",
        name: "Assunto",
        placeHolder: "Seu Assunto...",
        class:"form-input bg-[#f8f9fa] border !border-[#edf0f3] focus:!border-custom"
    },
    {
        id:4,
        type: "textarea",
        name: "Mensagem",
        placeHolder: "Sua Messagem...",
        class:"form-input bg-[#f8f9fa] border !border-[#edf0f3] focus:!border-custom h-auto"
    },
]

export default overviewData