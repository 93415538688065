import img1 from '../../../assets/images/icon/check.svg'


const feature = [
    {
        id: 1,
        image: img1,
        description:"Perguntas",
    },
    {
        id: 2,
        image: img1,
        description:"Dados do requerente",
    },
    {
        id: 3,
        image: img1,
        description:"Pagamento",
    },
]


export default feature