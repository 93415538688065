const faq = [
    {
        id: 1,
        question:"Como funciona a elaboração dos recursos?",
        answer:"São baseados no CTB (Código de Trânsito Brasileiro) e nas Resoluções do CONTRAN, elaboramos os recursos conforme a legislação de trânsito vigente, e orientado pelas suas respostas."
    },
    {
        id: 2,
        question:"Qual a experiência da empresa na área de recursos?",
        answer:"Nossa equipe tem amplo conhecimento adquirido através de cursos especializados na área das leis de trânsito, assim como na própria experiência dentro de todo os recursos deferidos que anularam as infrações."
    },
    {
        id: 3,
        question:"Como posso pagar pelo recurso?",
        answer:"O pagamento é feito por cartão de crédito, débito, QR Code ou Pix utilizando o sistema da Cielo Pagamentos."
    },
    {
        id: 4,
        question:"Qual o prazo o recurso será enviado?",
        answer:"O recurso será enviado no e-mail cadastrado no prazo de até 24 horas após a confirmação do pagamento."
    },
    {
        id: 5,
        question:"Os recursos valem para todas as instâncias?",
        answer:"Não, o recurso gerado vale somente para a instância indicada por você. Havendo necessidade de recorrer sua multa novamente, crie um novo recurso para a instância desejada."
    },
    {
        id: 5,
        question:"Quem faz o protocolo?",
        answer:"O protocolo é realizado pelo condutor. Nós iremos desenvolver o recurso e encaminharemos no e-mail cadastrado, onde o condutor deverá imprimir, assinar e protocolar junto ao órgão, respeitando a data limite."
    },
]

export default faq