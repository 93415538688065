import React, { useEffect } from "react";
import overviewData from "../../assets/fake-data/overview";
import contact from "../../assets/fake-data/landingpage-three/contact";

const ContactSection = () => {

  const handleClick = () => {
    try {
      var blipClient = new BlipChat();// eslint-disable-line
      blipClient.withAppKey('bWt0bHZ3eXFqZG4zdTJjamFoc3NlZmthOmI2MzkwYjU1LTBiMmEtNDliNi1iNDBjLTRhZmFjNTZkNTFmZg==')
      blipClient.toogleChat();
    } finally {
      console.log("Error localhost")
    }
    
  };


  return (
    <>
      <section className="py-20">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="max-w-[500px] w-full mx-auto">
              <form className="space-y-6">
                {/* {overviewData.map((x) => {
                  return (
                    <div key={x.id}>
                      <input
                        type={x.type}
                        className="form-input rounded-md !h-[55px] bg-white border !border-[#ced4da] focus:!border-custom"
                        placeholder={x.placeHolder}
                        required=""
                      />
                    </div>
                  );
                })} */}
                <div>
                  <button type="button" className="btn-custom w-full" onClick={ (e) => handleClick()}>
                    Clique para enviar uma mensagem
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="bg-dark/[0.04] h-[1px] my-[70px] w-full inline-block"></div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-[30px]">
            {contact.map((x) => {
              return (
                <div key={x.id} className="text-center">
                  <div className="bg-custom/20 w-[50px] h-[50px] flex items-center mx-auto justify-center leading-[50px] rounded-lg">
                    <img
                      src={x.img}
                      alt={x.img}
                      className="mx-auto inline-block h-[30px]"
                    />
                  </div>
                  <h4 className="mt-4 font-bold text-xl">{x.name}</h4>
                  <p className="mt-2 text-muted">{x.text}</p>
                  <p className="mt-1 text-muted">{x.subText}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactSection;
