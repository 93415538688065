import React from "react";
import FooterTwo from "../components/footers/FooterTwo";
import ContactSection from "../features/contact/ContactSection";
import MainHeroSection from "../features/common/MainHeroSection";

const ContactPage = () => {
  return (
    <>
      <MainHeroSection title="Quer entrar em contato conosco?" subTitle="Vamos te ajudar!" />
      <ContactSection />
      <FooterTwo />
    </>
  );
};

export default ContactPage;
