import React, { useState } from "react";
import pricing from "../../assets/fake-data/landingpage-one/pricing";
import { Link } from "react-router-dom";

const Pricing = () => {
  const [dataBlock] = useState({
    title: "What's our monthly pricing subscription",
    subTitle:
      "Due to its widespread use as filler text for layouts, non-readability is of great importance.",
  });
  return (
    <>
      <section className="py-20">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="text-center">
              <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {dataBlock.title}
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
                {dataBlock.subTitle}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-16 gap-[30px]">
            {pricing.map((x, index) => {
              return (
                <div key={index}
                  className={`py-10 px-[25px] relative ${
                    x.bestPlan == true
                      ? "text-white overflow-hidden shadow-[0_0.75rem_6rem_rgb(56,65,74,0.3)] active bg-custom rounded mx-auto"
                      : ""
                  }`}
                >
                  {x.bestPlan ? (
                    <div className="bg-white py-[6px] text-center w-[200px] absolute top-[22px] -right-[62px] rotate-45">
                      <h6 className="uppercase text-custom text-xs">
                        Best &nbsp;Plan
                      </h6>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="text-center">
                    <h6
                      className={`${
                        x.bestPlan ? "text-white/80" : "text-dark/40 leading-5"
                      }`}
                    >
                      {x.planName}
                    </h6>
                  </div>
                  <div
                    className={`mt-10 text-center ${
                      x.bestPlan ? "text-white" : ""
                    }`}
                  >
                    <h1 className="mb-0 text-[56px] font-semibold">
                      {x.price}
                      <sub
                        className={`${
                          x.bestPlan ? "text-white" : "text-custom"
                        } text-sm`}
                      >
                        {x.subText}
                      </sub>
                    </h1>
                  </div>
                  <div className="w-full h-[1px] bg-[#efefef] my-10"></div>
                  <div
                    className={` ${
                      x.bestPlan ? "text-white/80" : "text-dark/50"
                    } text-dark/50 px-[30px] text-center`}
                  >
                    <p className="mb-0 leading-[1.5]">{x.description}</p>
                  </div>
                  <div className="text-center mt-12">
                    <Link
                      to="#"
                      className={` ${
                        x.bestPlan
                          ? "btn-white hover:-translate-y-[3px]"
                          : "btn-custom-light"
                      } `}
                    >
                      {x.link}
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
