import chartBar from '../../images/icon/chart-bar.svg'


const counter = [
    {
        id:1,
        image: chartBar,
        startAt : 0,
        endAt: 10,
        suffix: "",
        prefix: "+",
        description:'Anos de mercado.'
    },
    {
        id:2,
        image: chartBar,
        startAt : 0,
        endAt: 5000,
        suffix: "",
        prefix: "+",
        description:'CNH’s recuperadas.'
    },
    {
        id:3,
        image: chartBar,
        startAt : 0,
        endAt: 90,
        suffix: "%",
        prefix: "",
        description:'Dos clientes satisfeitos'
    },
    {
        id:4,
        image: chartBar,
        startAt : 0,
        endAt: 2000,
        suffix: "",
        prefix: "+",
        description:'Multas canceladas.'
    },
]

export default counter