import mailIcon from '../../images/icon/mail.svg'
import callIcon from '../../images/icon/active-call.svg'
import timeSchedule from '../../images/icon/time-schedule.svg'

const contact = [
    {
        id: 1,
        img: mailIcon,
        name: "Email",
        text: "contato@cancelamultas.com.br",
        subText: null
    },
    {
        id: 1,
        img: callIcon,
        name: "telefone",
        text: "(31) 9257-6624",
        subText: null
    },
    {
        id: 3,
        img: timeSchedule,
        name: "Horários",
        text: "Segunda a sexta",
        subText: "9:00am To 6:00pm (GMT)"
    },
]

export default contact